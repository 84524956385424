import axios from "axios";

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000/api"
    : "https://wee-emylee-luxecosmetique-28303f8f.koyeb.app/api";
export const TOKEN = () => {
  if (localStorage?.getItem("persist:root")) {
    return JSON.parse(JSON.parse(localStorage?.getItem("persist:root"))?.user)
      ?.currentUser?.accessToken;
  }
};

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    token: `Bearer ${TOKEN()}`,
  },
});
